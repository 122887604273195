import React from 'react'

function Footer() {
  return (
    <>
    <footer className='bg-warning fixed-bottom' >
    <div className="container">
    <div className='row'> 
    <div className='col-md-12 mt-3 text-center'> 
    <p>Copyright Irawan M</p>
    </div> 
    </div>   
    </div>
    </footer>
    </>
  )
}

export default Footer