import React from 'react'

function Home() {
  return (
    <div className="container">
    <div className='row'> 
    <div className='col-md-12 mt-3 text-start'> 
    include 'dbconfig.php';

 
 <p>// Create connection </p>
 
 <p>// Create connection </p>
 
 <p>// Create connection </p>
 
 <p>// Create connection </p>
 
 <p>// Create connection </p>

 

 
    </div> 
    </div>   
    </div>
  )
}

export default Home