import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PuffLoader from 'react-spinners/PuffLoader'

function Userlist() {

  const [userlist,setUserlist]=useState([])
  const [isLoading,setIsloading]=useState(false)

  useEffect(()=>{
    getUserlist()
  },[])


   const getUserlist=async ()=>{
    setIsloading(true)
    const res=await axios.get("https://reactjs.angsoft.info/testreact/user.php")
   try{
       setUserlist(res.data)
       console.log(res.data)
   }catch(error){
     console.log(error)
   }
  }
  

  return (
    <div className="container">
    <div className='row'> 
    <div className='col-md-12 mt-3'> 
    {isLoading?<PuffLoader size={50} color="green" cssOverride={{marginBlock:"5ren"}}/>:""}
    <h2>User List</h2>
    <table className='table table-bordered'>
    <thead>
    <th>No.ID</th><th>User Name</th><th>Email</th><th>Status</th><th>Action</th>
    </thead>
<tbody>
 {userlist.map((userData,index)=>(

<tr key={index}>
  <td>{userData.id}</td>
  <td>{userData.username}</td>
  <td>{userData.email}</td>
  <td>{userData.status==1?'Active':'Inactive'}</td>
  <td><Link to={"/editdata/"+userData.id} className='btn btn-success'>Edit</Link>
  <Link to={"/delete"+userData.id} className='btn btn-danger'>Delete</Link></td>
</tr>

 ))

}
</tbody>

    </table>

 
    </div> 
    </div>   
    </div>
  )
}

export default Userlist