import axios from 'axios'
import React, { useState } from 'react'
import { Form, useNavigate } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import PuffLoader from 'react-spinners/PuffLoader'

function Adduser() {
    const [message,setMessage]=useState('')
    const [isLoading,setIsloading]=useState(false)
    const Navigate=useNavigate()
    const [formvalue,setFormvalue]=useState({username:'',email:'',status:''})

    const handleInput=(a)=>{
        setFormvalue({...formvalue,[a.target.name]:a.target.value})
        // console.log(formvalue)

    }

    const handleSubmit=async(a)=>{
        a.preventDefault()
        setIsloading(true)
        const formData={username:formvalue.username,email:formvalue.email,status:formvalue.status}
        const res=await axios.post('https://reactjs.angsoft.info/testreact/user.php',formData)
        if(res.data.success)
        {
            setMessage(res.data.success)
            setTimeout(()=>{
                Navigate('/userlist')
            },2000)
            
        }
        
    }

  return (
    <div className="container">
    <div className='row'> 
    <div className='col-md-6 mt-4 text-start'> 
    {isLoading ? <PuffLoader size={40} color='blue' cssOverride={{marginBlock:"2rem"}}/>:""}
    <h2>Adduser</h2>
    <p className='text-warning'>{message}</p>
    <form onSubmit={handleSubmit}>
     <div className='mb-3 mt-3 row'>
     <label  className='col-sm-2'>User Name</label> 
     <div className='col-sm-10'>
     <input type='text' name='username' value={formvalue.username} className='form-control' onChange={handleInput}/>
     </div>
     </div>

     <div className='mb-3 row'>
     <label   className='col-sm-2'>Email</label> 
     <div className='col-sm-10'>
     <input type='text' name='email' value={formvalue.email} className='form-control' onChange={handleInput}/>
     </div>
     </div>

     <div className='mb-3 row'>
     <label   className='col-sm-2'>Status</label> 
     <div className='col-sm-10'>
     <select name='status' value={formvalue.status}  className='form-control' onChange={handleInput}>
     <option value=''>--Select Status--</option>
     <option value='1'>Active</option>
     <option value='0'>InActive</option>
     </select>
     </div>
     </div>


     <div className='mb-3 row'>
     <label  className='col-sm-2'></label> 
     <div className='col-sm-6'>
     <button name='submit' className='btn btn-success'>Submit</button>
     
     </div>
     </div>

    </form>

    </div> 
    </div>   
    </div>
  )
}

export default Adduser