import "./App.css";

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";
import { BrowserRouter,Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Home from "./component/home";
import Header from "./component/header";
import Userlist from "./component/userlist";
import Footer from "./component/footer";
import Adduser from "./component/adduser";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Header />
      
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/userlist' element={<Userlist/>}></Route>
        <Route path='/adduser' element={<Adduser/>}></Route>
      </Routes>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
